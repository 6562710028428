<template>
  <div>
    <LoadingMessage v-if="loading" />
    <vca-card v-else>
      <h2>{{ $t("events.view.header", { 0: event.name }) }}</h2>
      <vca-card class="shadowed">
        <vca-column>
          <EventApplication :event="event" :showApplication="true" />
        </vca-column>
      </vca-card>
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingMessage from "@/components/utils/LoadingMessage";
import EventApplication from "@/components/events/application/EventApplication";
export default {
  name: "EventsParticipants",
  components: { LoadingMessage, EventApplication },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.$store.commit("events/current", { id: this.$route.params.id });
    this.$store
      .dispatch("events/getView")
      .then(() => {
        if (
          !(
            this.hasSystemPermission() ||
            this.hasPoolPermission(this.poolEventPermissions) ||
            (this.user && this.event.event_asp_id == this.user.id) ||
            this.event.isPublished
          )
        ) {
          this.$router.push({ name: "NotFoundView" });
        } else {
          const breadCrumb =
            '<a href="' +
            window.location.href +
            '" title="' +
            this.$t("events.view.header", { 0: this.event.name }) +
            '">' +
            this.$t("events.view.header", { 0: this.event.name }) +
            "</a>";
          this.$store.commit("breadCrumb", breadCrumb);
        }
        this.loading = false;
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.notification({
            title: this.$t("messages.title.error"),
            body: this.$t("messages.error.page_404"),
            type: "error",
          });
          this.$router.push({ path: "/events" });
        }
      });

    if (this.user) {
      this.$store
        .dispatch({ type: "events/participations/listUser" })
        .then((response) => {
          this.$store.commit(
            "events/participations/list",
            !response || response.length == 0 ? [] : response
          );
        });
    }
  },
  computed: {
    ...mapGetters({
      event: "events/current",
      publishedstates: "events/publishedstates",
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    current: {
      get() {
        return this.$store.state.events.participations.current;
      },
      set(value) {
        this.$store.commit("events/participations/current", value);
      },
    },
    participant: {
      get() {
        return this.$store.state.events.current;
      },
      set(value) {
        this.$store.commit("events/current", value);
      },
    },
  },
};
</script>
